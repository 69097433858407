import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/index.css";
import store from "./store";
import popUpPlugin from "@/components/popUpPlugin";
import timerPicker from "@/components/timer-picker";
import backToTop from "@/components/backToTop";
import tabBarHeader from "@/components/tabBar-header";
import VueClipboard from "vue-clipboard2";

import "@/vant/index";
Vue.component("popUpPlugin", popUpPlugin);
Vue.component("timerPicker", timerPicker);
Vue.component("backToTop", backToTop);
Vue.component("tabBarHeader", tabBarHeader);
Vue.use(VueClipboard);
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (store.state.token) {
    if (to.path !== "/login") {
      next();
    } else {
      next(from.path);
    }
  } else {
    if (to.path !== "/login") {
      next("/login");
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
