export const apiRoutes = {
  userLogin: "/UserAgent/UsersLogin", //登录
  agencyInfo: "/UserAccount/GetAgentSomeday", //某天的用户数据
  agencyCustomTimeInfo: "/UserAccount/GetAgentCustomTime", //自定义时间下的用户数据
  agencyreSourceList: "/UserAgent/QuerySubordinateAgentById", // 下级代理商
  agencyCommission: "/UserAccount/GetAgentCertainMonth", // 某月数据
  addUser: "/UserAgent/UserInsert", //新增代理商
  changePassword: "/UserAgent/UpdatePasswordByAccount", //代理商密码修改
  checkExsitUser: "/UserAgent/QueryUserByAccount", // 判断是否被注册
  userAccoutPage: "/UserAccount/UserAccountPage", // 用户分页数据
  agencyUserList: "/UserAgent/GetAgentPage", // 代理商分页数据
  subordinateAgentAmount: "/UserAgent/QuerySubordinateAgentCountById", // 下级代理商数量
};
