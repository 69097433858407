<template>
  <div>
    <div
      class="timer-picker"
      :class="{
        active: $route.name === 'data' || $route.name === 'user',
        agencyActive: $route.name === 'agency',
      }"
    >
      <div class="dropdown-menu" v-if="$route.path === '/data'">
        <span>筛选</span>
        <select v-model="selectedOption">
          <option
            :value="source.id"
            v-for="source in sourceList"
            :key="source.id"
          >
            {{ source.userNickName }}
          </option>
        </select>
      </div>

      <div class="enter-user" v-if="$route.path === '/user'">
        <img src="@/assets/images/user-img.png" alt="" />
        <input type="text" v-model="userName" placeholder="输入用户名称" />
      </div>

      <div class="time-container">
        <template v-if="$route.path !== '/commission'">
          <div class="time">
            <span>开始日期</span>
            <input
              type="text"
              readonly
              v-model="stratFullTime"
              @click="showPopup('start')"
            />
            <van-icon class="icon" name="calendar-o" />
          </div>
          <div class="time">
            <span>结束日期</span>
            <input
              type="text"
              readonly
              v-model="endFullTime"
              @click="showPopup"
            />
            <van-icon class="icon" name="calendar-o" />
          </div>
        </template>

        <template v-if="$route.path === '/commission'">
          <div class="time commission-time">
            <span>选择月份</span>
            <input
              type="text"
              readonly
              v-model="stratFullTime"
              @click="showPopup('start')"
            />
            <img src="@/assets/images/calendar.png" alt="" />
          </div>
        </template>
      </div>

      <div class="btn" @click="handleClick">
        <van-button
          size="small"
          round
          color="linear-gradient(to right, #64AEF8, #9ACCFF)"
          block
          >查询</van-button
        >
      </div>
    </div>
    <popUpPlugin ref="popUp" @getFullTime="getFullTime" />
  </div>
</template>

<script>
import {
  formatTime,
  formaTommorrowTime,
  isMaxGapTime,
  getThisMonth,
} from "@/utills/formatTime.js";
import {
  apiAgencyCustomTimeInfo,
  apiGetSourceList,
  apiGetAgencyCommission,
  apiGetUserAccoutPage,
  apiGetAgencyUserList,
} from "@/api/userApi";
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  data() {
    return {
      stratFullTime: "",
      endFullTime: "",
      type: "",
      userName: "",
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2025, 12, 30),
      currentDate: new Date(),
      pageIndex: 0,
      sourceList: [
        {
          id: 0,
          userNickName: "全部渠道",
        },
        {
          id: undefined,
          userNickName: "仅自己",
        },
      ],
      selectedOption: 0,
    };
  },

  async created() {
    if (this.$route.path === "/data") {
      this.sourceList[1].id = this.agentId;
      const res = await apiGetSourceList(this.agentId);
      if (res.data) {
        this.sourceList = [...this.sourceList, ...res.data];
      }
    }
  },

  async mounted() {
    console.log("agentAccount", this.agentAccount);
    if (this.$route.path === "/data") {
      const data = {
        agentAccount: this.agentAccount,
        beginTime: `${formatTime(Date.now())}`,
        endTime: `${formaTommorrowTime(Date.now())}`,
        selectType: this.selectedOption,
      };
      await this.sendApiAgencyCustomTimeInfo(data);
    } else if (this.$route.path === "/commission") {
      const month = getThisMonth(Date.now());
      const data = {
        agentAccount: this.agentAccount,
        beginTime: `${month.firstDatOfMonth}`,
        endTime: `${month.lastDatOfMonth}`,
        selectType: 0,
      };
      await this.sendApiGetAgencyCommission(data);
    }
  },

  computed: {
    ...mapState({
      agentAccount: (state) => state.userInfo.userAccout,
      agentId: (state) => state.userInfo.id,
      agentName: (state) => state.userInfo.userAccout,
    }),
  },

  methods: {
    showPopup(type) {
      this.$refs.popUp.show = true;
      this.type = type;
    },

    handleCancel() {
      this.show = false;
    },

    getFullTime(time) {
      if (this.type === "start") {
        this.stratFullTime = time;
      } else {
        this.endFullTime = time;
      }
    },

    async handleClick() {
      if (this.stratFullTime == "") {
        return Toast.fail(
          `${
            this.$route.path === "/commission" ? "请选择月份" : "请选择开始时间"
          }`
        );
      } else if (
        this.$route.path !== "/commission" &&
        this.endFullTime === ""
      ) {
        return Toast.fail("请选择结束时间");
      } else if (isMaxGapTime(this.stratFullTime, this.endFullTime)) {
        return Toast.fail("选择的时间段不允许超过一个月");
      }

      try {
        if (this.$route.path === "/data") {
          const data = {
            agentAccount: this.agentAccount,
            beginTime: `${formatTime(new Date(this.stratFullTime))}`,
            endTime: `${formatTime(new Date(this.endFullTime))}`,
            selectType: this.selectedOption,
          };

          await this.sendApiAgencyCustomTimeInfo(data);
        } else if (this.$route.path === "/commission") {
          const month = getThisMonth(this.stratFullTime);
          const data = {
            agentAccount: this.agentAccount,
            beginTime: `${month.firstDatOfMonth}`,
            endTime: `${month.lastDatOfMonth}`,
            selectType: 0,
          };
          await this.sendApiGetAgencyCommission(data);
        } else if (this.$route.path === "/user") {
          this.pageIndex = 0;
          const data = {
            userName: this.userName,
            agentAccount: this.agentAccount,
            beginTime: `${formatTime(new Date(this.stratFullTime))}`,
            endTime: `${formatTime(new Date(this.endFullTime))}`,
            pageSize: 20,
          };

          this.$emit("handlerQueryData", data);
        } else if (this.$route.path === "/agency") {
          this.pageIndex = 0;
          const data = {
            isWin: 0,
            // agentName:this.agentName,
            agentName: "",
            agentAccount: this.agentAccount,
            beginTime: `${formatTime(new Date(this.stratFullTime))}`,
            endTime: `${formatTime(new Date(this.endFullTime))}`,
            pageSize: 20,
          };
          this.$emit("handlerQueryAgencyData", data);
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    //数据报表
    async sendApiAgencyCustomTimeInfo(data) {
      try {
        const res = await apiAgencyCustomTimeInfo(data);
        if (res.success) {
          this.$store.commit("SET_AGENCY_INFO_DATA", res.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    //佣金报表
    async sendApiGetAgencyCommission(data) {
      try {
        const res = await apiGetAgencyCommission(data);
        if (res.success) {
          this.$store.commit("SET_COMMISSION_INFO", res.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    //用户与代理 数据
    async sendApiGetUserAccoutPage(data) {
      try {
        if (this.$route.path === "/user") {
          const res = await apiGetUserAccoutPage(data);
          if (res.success) {
            return res.data;
          }
        } else if (this.$route.path === "/agency") {
          //  console.log('1111agency');
          const res = await apiGetAgencyUserList(data);
          if (res.success) {
            return res.data;
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.timer-picker {
  font-size: 35px;
  width: 92%;
  padding: 0 5px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(237, 241, 246, 0.8);
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  padding-bottom: 20px;
  .btn {
    width: 320px;
    height: 60px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .dropdown-menu {
    font-size: 24px;
    margin-left: 60px;
    margin-top: 20px;
    color: #767676;
    font-weight: bold;
    select {
      width: 212px;
      height: 60px;
      margin-left: 20px;
      text-indent: 1em;
      border: 1px solid #767676;
      border-radius: 10px;
      outline: none;
    }
  }

  .enter-user {
    margin-left: 10px;
    margin-top: 20px;
    input {
      outline: none;
      border: none;
      font-size: 30px;
    }
    img {
      width: 24px;
    }
  }
  .time-container {
    display: flex;
    margin-top: 30px;
    color: #767676;
    font-size: 28px;

    .time {
      width: 350px;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-around;
      font-weight: bold;
      input {
        width: 212px;
        height: 50px;
        border: 1px solid #767676;
        text-indent: 1em;
        border-radius: 10px;
        outline: none;
      }

      .icon {
        position: absolute;
        right: 20px;
      }
    }

    .commission-time {
      margin-top: 30px;
      width: 100%;
      // margin-left: 50%;
      // transform: translateX(-50%);
      input {
        width: 470px;
        height: 66px;
        outline: none;
        border: 1px solid #767676;
        border-radius: 10px;
        text-align: center;
      }

      img {
        width: 40px;
        position: absolute;
        right: 40px;
      }
    }
  }
}

.active {
  top: 150px;
}

.agencyActive {
  top: 350px;
}
</style>
