import instance from "../requst.js";
import { apiRoutes } from "../apiRoutes.js";

export const apiUserLogin = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.userLogin,
    data,
  });
};

export const apiAgencyInfo = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.agencyInfo,
    data,
  });
};

export const apiAgencyCustomTimeInfo = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.agencyCustomTimeInfo,
    data,
  });
};

export const apiGetSourceList = (id) => {
  return instance({
    method: "get",
    url: `${apiRoutes.agencyreSourceList}/${id}`,
  });
};

export const apiGetAgencyCommission = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.agencyCommission,
    data,
  });
};

export const apiAddNewAgency = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.addUser,
    data,
  });
};

export const apiChangePassword = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.changePassword,
    data,
  });
};

export const apiChcekExsitUser = (account) => {
  return instance({
    method: "get",
    url: `${apiRoutes.checkExsitUser}/${account}`,
  });
};

export const apiGetUserAccoutPage = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.userAccoutPage,
    data,
  });
};

export const apiGetAgencyUserList = (data) => {
  return instance({
    method: "post",
    url: apiRoutes.agencyUserList,
    data,
  });
};

export const apiGetSubordinateAgentAmount = (id) => {
  return instance({
    method: "get",
    url: `${apiRoutes.subordinateAgentAmount}/${id}`,
  });
};
