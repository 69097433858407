import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "home",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main-page/main-page.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/home-page/home-page.vue"
          ),
      },
      {
        path: "data",
        name: "data",
        meta: {
          title: "数据报表",
        },
        component: () =>
          import(
            /* webpackChunkName: "data" */ "../views/data-info/data-info.vue"
          ),
      },
      {
        path: "commission",
        name: "commission",
        meta: {
          title: "佣金报表",
        },
        component: () =>
          import(
            /* webpackChunkName: "commission" */ "../views/commission/commission.vue"
          ),
      },
      {
        path: "user",
        name: "user",
        meta: {
          title: "用户信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/user-info/user-info.vue"
          ),
      },
      {
        path: "agency",
        name: "agency",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/user-agency/user-agency.vue"
          ),
      },
      {
        path: "add",
        name: "addAgency",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/add-agency/add-agency.vue"
          ),
      },
      {
        path: "setting",
        name: "setting",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/user-setting/user-setting.vue"
          ),
      },
      {
        path: "userdetail",
        name: "userdetail",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/user-detail/user-detail.vue"
          ),
      },
      {
        path: "setpass",
        name: "setpass",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/set-pass/set-pass.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "Login" */ "../views/user-login/user-login.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
