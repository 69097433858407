import Vue from "vue";
import Vuex from "vuex";
import { routes } from "@/router";
import store2 from "store2";
import { setuserInfo } from "@/utills/constant.js";

Vue.use(Vuex);

const state = {
  routes,
  token: store2.get(setuserInfo.SET_USER_H5_TOKEN),
  userInfo: store2.get(setuserInfo.SET_USER_INFO),
  agencyInfoData: {},
  commissionObj: {},
  userAccountInfo: {},
};
const mutations = {
  LOGIN_IN(state, token) {
    state.token = token;
    store2.set(setuserInfo.SET_USER_H5_TOKEN, token);
  },

  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
    store2.set(setuserInfo.SET_USER_INFO, userInfo);
  },

  SET_AGENCY_INFO_DATA(state, agencyInfoData) {
    state.agencyInfoData = agencyInfoData;
  },

  SET_COMMISSION_INFO(state, commissionObj) {
    state.commissionObj = commissionObj;
  },

  SET_AGENT_USER_INFO(state, userAccountInfo) {
    state.userAccountInfo = userAccountInfo;
  },

  SET_USER_LOGIN_OUT(state) {
    state.token = "";
    store2.remove(setuserInfo.SET_USER_H5_TOKEN);
  },
};

const actions = {};

const getters = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
