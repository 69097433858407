<template>
  <div class="back-top" v-show="showTop" @click="handleScrollToTop">
    <van-icon name="back-top" color="#fff" />
  </div>
</template>

<script>
export default {
  props: {
    refNode: {
      type: HTMLDivElement,
      default: "",
    },
  },
  data() {
    return {
      showTop: false,
    };
  },
  methods: {
    handleScrollToTop() {
      this.refNode.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      if (this.refNode?.scrollTop > 0) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.back-top {
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  position: absolute;
  right: 10px;
  bottom: 40px;
}
</style>
