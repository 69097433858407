import moment from "moment";

export const formatTime = function (time) {
  return moment(time).format("YYYY-MM-DD");
};

export const formaTommorrowTime = function (time) {
  const oneDay = 24 * 60 * 60 * 1000;
  const tomorrowTimestamp = time + oneDay;

  return moment(tomorrowTimestamp).format("YYYY-MM-DD");
};

export const isMaxGapTime = function (startTime, endTime) {
  const timeDifference = Math.abs(new Date(endTime) - new Date(startTime));
  const millisecondsInDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
  const daysDifference = Math.floor(timeDifference / millisecondsInDay);
  return daysDifference > 32;
};

export const getThisMonth = function (startTime) {
  const time = formatTime(startTime);
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDatOfMonth = formatTime(new Date(year, month, 1));
  const lastDatOfMonth = formatTime(new Date(year, month + 1, 0));
  return { firstDatOfMonth, lastDatOfMonth };
};
