<template>
  <van-popup position="bottom" v-model="show" :close-on-click-overlay="false">
    <van-datetime-picker
      v-model="currentDate"
      :type="$route.path === '/commission' ? 'year-month' : 'date'"
      title="只提供近3个月数据查询"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    />
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      minDate:
        this.$route.path === "/commission"
          ? new Date(new Date().getFullYear(), 0)
          : new Date(2000, 0, 1),
      maxDate:
        this.$route.path === "/commission"
          ? new Date(new Date().getFullYear(), 11)
          : new Date(2025, 11, 30),
      currentDate: new Date(),
      show: false,
    };
  },
  methods: {
    handleConfirm() {
      const date = new Date(this.currentDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      this.$emit(
        "getFullTime",
        `${year}/${month}${this.$route.path === "/commission" ? "" : `/${day}`}`
      );
      this.show = false;
    },
    handleCancel() {
      this.show = false;
    },
  },
};
</script>

<style>
.van-picker-column__item--selected {
  color: #4eb3fd !important;
}
.van-hairline-unset--top-bottom {
  border-top: 1px solid #4eb3fd;
  border-bottom: 1px solid #4eb3fd;
}

.van-picker__toolbar {
  background: #f3f8fe;
}

.van-picker__cancel {
  color: #4eb3fd !important;
}

.van-picker__confirm {
  color: #4eb3fd !important;
}
</style>
