import axios from "axios";
import store from "@/store";
import localStorage from "store2";
import { Toast } from "vant";
import { setuserInfo } from "@/utills/constant.js";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 设置基本的 API URL
  timeout: 5000, // 设置请求超时时间
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.get(setuserInfo.SET_USER_H5_TOKEN);
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    console.log("error", error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const { data } = response || {};
    return data;
  },
  (error) => {
    // 响应错误处理
    if (error.response.status === 401) {
      store.commit("SET_USER_LOGIN_OUT");
      Toast("登录过期,\n请重新登录");
      setTimeout(() => {
        location.reload();
      }, 500);
      return;
    }
  }
);

export default instance;
