<template>
  <div class="tabBar-header">
    <div @click="handleTabBarRoute">
      <img src="@/assets/images/gray-arrow.png" alt="" />
    </div>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    handleTabBarRoute() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/variable.less";
.tabBar-header {
  display: flex;
  width: 100%;
  height: @addTabBar-height;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  color: #878787;
  span {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -48px;
    font-size: 40px;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
</style>
