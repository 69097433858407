import Vue from "vue";
import {
  Button,
  Icon,
  DatetimePicker,
  Popup,
  Tabbar,
  TabbarItem,
  Toast,
  Form,
  Field,
  Checkbox,
  CheckboxGroup,
  List,
} from "vant";

[
  Button,
  Icon,
  DatetimePicker,
  Popup,
  Tabbar,
  TabbarItem,
  Toast,
  Form,
  Field,
  Checkbox,
  CheckboxGroup,
  List,
].forEach((el) => {
  Vue.use(el);
});
